import { API, AdultAPI, removeBlankAttributes } from "@project/shared"
export type NotificationQueries = {
  page?: number
  pageSize?: number | string
  keyword?: string
  serviceType?: string
}
export type NotificationObj = {
  created_by?: string
  created_datetime?: string
  deleted_by?: string
  deleted_datetime?: string
  id?: number
  is_deleted?: boolean
  notification_details?: string
  notification_title?: string
  notification_type?: string
  publish_status?: string
  publishing_date?: string
  updated_by?: string
  updated_datetime?: string
  url?: string
}
export type NotificationResponseObj = {
  count: number
  data: NotificationObj[]
}
export const getAllNotifications = (
  queries: NotificationQueries
): Promise<NotificationResponseObj> => {
  queries.page = 1
  queries.pageSize = "Infinity"
  const params = removeBlankAttributes(queries)
  return API.get("/notifications", {
    params,
  })
}

export const getAllAdultNotifications = (
  queries: NotificationQueries
): Promise<NotificationResponseObj> => {
  queries.page = 1
  queries.pageSize = "Infinity"
  const params = removeBlankAttributes(queries)
  return AdultAPI.get("/notifications", {
    params,
  })
}

export const getNotification = (
  id: number
): Promise<{ data: NotificationObj }> => {
  return API.get(`/notifications/${id}`)
}
// adding comments to trigger deployment

export const getAdultNotification = (
  id: number
): Promise<{ data: NotificationObj }> => {
  return AdultAPI.get(`/notifications/${id}`)
}

export const createNewNotification = (values: any) => {
  return API.post("/notifications", { ...values })
}

export const createNewAdultNotification = (values: any) => {
  return AdultAPI.post("/notifications", { ...values })
}

export const updateNotification = ({ id, ...rest }: any) => {
  return API.put(`/notifications/${id}`, { ...rest })
}

export const updateAdultNotification = ({ id, ...rest }: any) => {
  return AdultAPI.put(`/notifications/${id}`, { ...rest })
}

export const deleteNotification = (id: number | string) => {
  return API.delete(`/notifications/${id}`)
}

export const deleteAdultNotification = (id: number | string) => {
  return AdultAPI.delete(`/notifications/${id}`)
}
