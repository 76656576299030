import {
  DatePicker,
  notification,
  Popconfirm,
  Select,
  Spin,
  Button as AntButton,
} from "antd"
import locale from "antd/lib/date-picker/locale/ja_JP"
import { useFormik } from "formik"
import { t } from "i18next"
import moment from "moment"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
//components imports
import {
  TextField,
  Grid,
  PageCard,
  RadioButtons,
  Button,
  Required,
  BreadCrumb,
  notificationTypes,
  dynamicLangString,
  theme,
} from "@project/shared"
//services imports
import {
  createNewAdultNotification,
  createNewNotification,
  deleteAdultNotification,
  deleteNotification,
  getAdultNotification,
  getNotification,
  NotificationObj,
  updateAdultNotification,
  updateNotification,
} from "../../../services/notification"
//util imports
import { scrollToFirstErrorField } from "../../../utils"

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  .inner-grid-content {
    width: 100%;
  }
  .min-width {
    min-width: 150px;
  }
  .grid-header {
    width: 300px;
    padding: 28px 55px 28px 18px;
  }
  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input,
  textarea {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .grid-header {
      width: 100%;
      padding: 10px;
    }
  }

  .text-required {
    color: #e00;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  .text-link {
    color: #0782c8;
    text-decoration: underline;
    display: inline-flex;
    margin-right: 15px;
  }
  .text-field-headertext {
    margin-top: 20px;
  }
  .button-wrapper {
    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }
  .button-wrapper-with-delete {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ant-delete-button {
      background: #f3f3f3;
      color: #191919;
      border-color: #d2d1d1;
      height: 40px;
      border-radius: 20px;
    }
  }
  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #0782c8;
  }
  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
  & small {
    display: block;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
  .update-button {
    @media (max-width: 491px) {
      margin-top: 10px;
    }
  }

  .error-text {
    color: ${theme.red4};
    font-size: 12px;
  }
`

const publish_satuts_option = [
  {
    label: t("Public"),
    value: "public",
  },
  {
    label: t("Private"),
    value: "private",
  },
]

const user_service_option = [
  {
    label: t("Child1"),
    value: "C",
  },
  {
    label: t("Adult1"),
    value: "A",
  },
]

interface Props {
  id?: number
  service_type?: string
}

const NotificationForm: React.FC<any> = (props: Props) => {
  moment.updateLocale("ja", {
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "01月",
      "02月",
      "03月",
      "04月",
      "05月",
      "06月",
      "07月",
      "08月",
      "09月",
      "10月",
      "11月",
      "12月",
    ],
  })
  const { id, service_type } = props
  const { t } = useTranslation()
  const router = useRouter()

  const breadCrumbItems = [
    {
      key: "notification",
      name: t("Notification list"),
      href: "/notification",
    },
    {
      key: id ? "edit-form" : "add-form",
      name: id ? t("Edit form") : t("Add form"),
    },
  ]

  const initialValues = {
    notification_title: "",
    notification_type: "",
    notification_details: "",
    url: "",
    publishing_date: moment().format("YYYY-MM-DD"),
    publish_status: "public",
    service_type: "C",
  }

  const validationSchema = yup.object().shape({
    notification_title: yup.string().required(t("Required")).max(200),
    notification_type: yup.string().required(t("Required")),
    notification_details: yup.string().required(t("Required")).max(2000),
    url: yup.string().url(t("Please enter a valid URL")),
    publishing_date: yup.date().typeError(t("Required")),
    publish_status: yup.string().required(t("Required")),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (id) {
        editNotification({ id, ...values })
      } else {
        createNotification(values)
      }
    },
  })

  //update notification mutation
  const { mutate: editNotification, isLoading: isUpdating } = useMutation(
    formik.values.service_type === "C"
      ? updateNotification
      : updateAdultNotification,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Notification", "Updated Successfully"]),
        })
        router.back()
      },
      onError: (error: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )
  //create notification mutation
  const { mutate: createNotification, isLoading: isCreating } = useMutation(
    formik.values.service_type === "C"
      ? createNewNotification
      : createNewAdultNotification,
    {
      onSuccess: () => {
        notification.success({
          message: t("Notification") + t("Created Successfully"),
        })
        router.push("/notification")
      },
      onError: () => {
        notification.error({
          message: t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  //delete notificaion
  const { mutate: removeNotification, isLoading: isDeleting } = useMutation(
    formik.values.service_type === "C"
      ? deleteNotification
      : deleteAdultNotification,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString(["Notification", "Deleted Successfully"]),
        })
        router.back()
      },
      onError: (error: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )

  const { isLoading } = useQuery<{ data: NotificationObj }, Error>({
    queryKey: ["notification", id],
    queryFn: () =>
      service_type == "C" ? getNotification(id) : getAdultNotification(id),
    enabled: !!id,
    onSuccess: (res) => {
      formik.setFieldValue("notification_title", res?.data?.notification_title)
      formik.setFieldValue("notification_type", res?.data?.notification_type)
      formik.setFieldValue(
        "notification_details",
        res?.data?.notification_details
      )
      formik.setFieldValue("url", res?.data?.url)
      formik.setFieldValue(
        "publishing_date",
        moment(res?.data?.publishing_date).format("YYYY-MM-DD")
      )
      formik.setFieldValue("publish_status", res?.data?.publish_status)
      formik.setFieldValue("service_type", service_type)
    },
    onError: () => {
      router.push(`/notification`)
      return null
    },
  })

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  return (
    <>
      <BreadCrumb items={breadCrumbItems} />
      <PageCard title={t("Set up notifications")} style={{ marginTop: "22px" }}>
        <Spin spinning={isLoading} size={"large"}>
          <StyledForm onSubmit={formik.handleSubmit}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Notification title")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"notification_title"}
                height={"50px"}
                value={formik.values.notification_title}
                onChange={formik.handleChange}
                showCounting
                className={getErrorClass("notification_title", "input")}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.notification_title &&
                  formik.errors?.notification_title &&
                  formik.errors?.notification_title
                }
              />
              <small>{"(200文字以内)"}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Notification type")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <Select
                options={notificationTypes}
                style={{ width: "50%" }}
                value={formik.values.notification_type}
                onChange={(value) =>
                  formik.setFieldValue("notification_type", value)
                }
                size={"large"}
                className={getErrorClass("notification_type", "nest")}
                onBlur={formik.handleBlur}
              />
              <p className={"error-text"}>
                {formik.touched.notification_type &&
                  formik.errors?.notification_type &&
                  formik.errors?.notification_type}
              </p>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Notification details")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"notification_details"}
                type={"textarea"}
                value={formik.values.notification_details}
                onChange={formik.handleChange}
                rows={11}
                showCounting
                className={getErrorClass("notification_details", "input")}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.notification_details &&
                  formik.errors?.notification_details &&
                  formik.errors?.notification_details
                }
              />
              <small>{"(2,000文字以内)"}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("URL")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                height={"50px"}
                name={"url"}
                value={formik.values.url}
                onChange={formik.handleChange}
                className={getErrorClass("url", "input")}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.url && formik.errors?.url && formik.errors.url
                }
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Publishing date")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <DatePicker
                locale={locale}
                name={"publishing_date"}
                placeholder={t("Select Date")}
                allowClear={true}
                value={
                  formik.values.publishing_date
                    ? moment(formik.values.publishing_date)
                    : null
                }
                onChange={(dateString) => {
                  formik.setFieldValue(
                    "publishing_date",
                    dateString ? moment(dateString).format("YYYY-MM-DD") : null
                  )
                }}
                className={`min-width ${getErrorClass(
                  "publishing_date",
                  "container"
                )}`}
                format={"YYYY年MM月DD日"}
                onBlur={formik.handleBlur}
              />
              <p className={"error-text"}>
                {formik.touched?.publishing_date &&
                  formik.errors?.publishing_date &&
                  formik.errors?.publishing_date}
              </p>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Use of Service")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={user_service_option}
                value={formik.values.service_type}
                onChange={(evt) => {
                  formik.setFieldValue("service_type", evt.target.value)
                }}
                className={getErrorClass("service_type", "input")}
                disabled={id ? true : false}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Publish status")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={publish_satuts_option}
                value={formik.values.publish_status}
                onChange={(evt) => {
                  formik.setFieldValue("publish_status", evt.target.value)
                }}
                className={getErrorClass("publish_status", "input")}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <div className={"button-wrapper-with-delete"}>
              <div className={"button-wrapper"}>
                <Button
                  background={"#F3F3F3"}
                  color={"#191919"}
                  bordercolor={"#D2D1D1"}
                  minheight={40}
                  onClick={() => router.back()}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  background={"#0782C8"}
                  bordercolor={"#0782C8"}
                  className={"update-button"}
                  htmlType={"submit"}
                  minheight={40}
                  loading={isCreating || isUpdating}
                >
                  {t("Save ")}
                </Button>
              </div>
              {!!id && (
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  okText={"OK"}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                  onConfirm={() => removeNotification(id)}
                >
                  <AntButton
                    className={"ant-delete-button"}
                    loading={isDeleting}
                  >
                    {t("delete")}
                  </AntButton>
                </Popconfirm>
              )}
            </div>
          </StyledForm>
        </Spin>
      </PageCard>
    </>
  )
}

export { NotificationForm }
